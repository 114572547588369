import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleAuthSignUp from "./components/googleSignup";
import GoogleAuthWrapper from "./authentication/SignupWrapper";
import HomeDashboard from "./components/HomeDashboard";
import HomeChat from "./components/HomeChat";
import HomeChatTable from "./components/HomeChatTable";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeDashboard />} />
        <Route path="/chat" element={<HomeChat />} />
        <Route path="/tab" element={<HomeChatTable />} />
        {/* <Route path="/" element={<GoogleAuthSignUp />} /> */}
        {/* <Route path="/googleAuthWrapper" element={<GoogleAuthWrapper />} />
        <Route path="/HomeDashboard" element={<HomeDashboard />} /> */}
 
      </Routes>
    </div>
  );
}

export default App;
